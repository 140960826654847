   /* client/src/components/Confirmation.css */
   .confirmation-card {
    max-width: 500px;
    margin: 50px auto;
    padding: 24px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .confirmation-details {
    margin: 24px 0;
  }

  .detail-item {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
    padding: 8px 0;
    border-bottom: 1px solid #f0f0f0;
  }
  .booking-confirmation-section {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  
  .booking-confirmation-group {
    display: flex;
    flex-direction: column;  /* Changed to column to stack vertically */
    align-items: center;
    gap: 12px;
  }
  
  .calendar-icons {
    display: inline-flex;
    gap: 8px;
  }
  
  .book-another-btn {
    margin-top: 5x;  /* Increased from 40px to 80px for double spacing */
    height: 48px;
    font-size: 18px;
    border-radius: 8px;
  }


  .detail-item:last-child {
    border-bottom: none;
  }

  /* Calendar button styles */
  .ant-btn-primary {
    height: 40px;
  }

  .ant-btn-primary .anticon {
    font-size: 20px;
  }

  /* Book another class button */
  .ant-btn-block {
    height: 45px;
    font-size: 16px;
  }